import { API_HEADER_AUTH_KEY,DEFAULT_LANGUAGE_ID }  from './global_constants';
import base64 	from 'base-64';
import Cookies 	from 'universal-cookie';
import axios 	from 'axios';
const cookies = new Cookies();

/* Creating axios instance */
var axiosInstance = axios.create({
	headers	: 	{"authkey" : API_HEADER_AUTH_KEY},
	data	:	{
		language_id : (cookies.get('preffered_language') || DEFAULT_LANGUAGE_ID )
	}
});

/* Request interceptor */
axiosInstance.interceptors.request.use((request)=>{
	const cookies 	=	new Cookies();
	let userDetails =	cookies.get('user_details');
	if(userDetails && userDetails._id) request.data['user_id'] = userDetails._id;
	if(userDetails && userDetails.token) request.headers['authorization'] = userDetails.token;

	/* To identify request is sending from web */
	if(!request.data.api_type) request.data.api_type = "web";
	let encodedData	=	base64.encode(JSON.stringify(request.data));
	request.data 	=	{data: encodedData};
	return request;
},(error)=>{
	/* Do something with request error */
	return Promise.reject(error);
});

/* Response interceptor */
axiosInstance.interceptors.response.use(response=> {
	let decodedData	=	JSON.parse(base64.decode(response.data));
	response.data	= 	decodedData;
	return response;
},(error)=>{
	/* Do something with response error */
	return Promise.reject(error);
});

export default axiosInstance;