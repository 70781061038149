/*global settings*/
import './../assets/css/footer.css';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Logo from './../assets/images/logo.svg';
import { translate } from './../helpers/intl_helpers';
import { Context }  from './../Auth.Context';
import {  USER_TYPE_VENDOR } from '../config/global_constants';

export default function Footer() {
	const [authDetails, ] = useContext(Context);
	return <>
		<footer className="footer">
			<div className="container">
				<div className="row">
					<div className="col-md-3 col-6 mb-4 mb-md-0 order-2 order-md-1">
						<h3>{translate('footer.wedding')}</h3>
						<ul className="footer-menu">
							<li>
								<Link to="/find-vendor">{translate('footer.find_vendor')}</Link>
							</li>
							<li>
								<Link to="/cms/how-it-works">{translate('footer.how_it_works')}</Link>
							</li>
						</ul>
					</div>
					<div className="col-md-3 col-6 mb-4 mb-md-0  order-3 order-md-2">
						<h3>{translate('footer.business')}</h3>
						<ul className="footer-menu">
							<li>
								<Link to="/become-vendor">{translate('footer.sign_up')}</Link>
							</li>
							<li>
								<Link to="/cms/help">{translate('footer.help')}</Link>
							</li>
						</ul>
					</div>
					<div className="col-md-3 col-6 mb-4 mb-md-0 order-4 order-md-3">
						<h3>{translate('footer.company')}</h3>
						<ul className="footer-menu">
							<li><Link to="/cms/about-us">{translate('footer.about_us')}</Link></li>
							<li><Link to="/contact-us">{translate('footer.contact')}</Link></li>
						</ul>
					</div>
					<div className="col-md-3 text-start text-md-end col-6 order-1 order-md-4">
						<Link to={authDetails && authDetails.user_type === USER_TYPE_VENDOR ? "/vendor" :"/"} className="footer-logo">
							<img src={Logo} alt="logo" width={126} height={86} />
						</Link>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="footer-copyright d-flex justify-content-between align-items-center">
							<p className="mb-0">{settings['Site.copyright_text'] ? settings['Site.copyright_text'] :""}</p>
							<ul className="footer-social d-flex align-items-center justify-content-end">
								{settings['Social.facebook'] &&
									<li className="ms-1 ms-md-2">
										<a href ={settings['Social.facebook']} target="_blank" rel="noreferrer" aria-label="facebook">
											<i className="fa-brands fa-facebook" />
										</a>
									</li>
								}
								{settings['Social.instagram'] &&
									<li className="ms-1 ms-md-2">
										<a href ={settings['Social.instagram']} target="_blank" rel="noreferrer" aria-label="instagram">
											<i className="fa-brands fa-instagram" />
										</a>
									</li>
								}
								{settings['Social.twitter'] &&
									<li className="ms-1 ms-md-2">
										<a href ={settings['Social.twitter']} target="_blank" rel="noreferrer" aria-label="twitter">
											<i className="fa-brands fa-twitter" />
										</a>
									</li>
								}
								{settings['Social.pinterest'] &&
									<li className="ms-1 ms-md-2">
										<a href ={settings['Social.pinterest']} target="_blank" rel="noreferrer" aria-label="pinterest">
											<i className="fa-brands fa-pinterest" />
										</a>
									</li>
								}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="animation-svg">
			<defs>
				<filter id="goo">
					<feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
					<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo"></feColorMatrix>
					<feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
				</filter>
			</defs>
		</svg>
	</>
}