/* API request complete path */
export const API_URL = process.env.REACT_APP_HOST+'/';

/* Website Url */
export const WEBSITE_URL = process.env.REACT_APP_HOST +'/';

/* Key for API authentication */
export const API_HEADER_AUTH_KEY = "WCN03JUEPD6L9XH7";

/* Images path */
export const WEBSITE_PUBLIC_IMG_URL			= 	API_URL+"frontend/images/";
export const WEBSITE_SETTINGS_IMG_URL		= 	API_URL+"frontend/uploads/settings/";
export const WEBSITE_IMG_URL 				= 	WEBSITE_URL+"images/";
export const CATEGORY_NO_IMAGE_AVAILABLE	=	"no-image.jpg";
export const VENDOR_ID_NO_IMAGE_AVAILABLE	=	"no-image.png";
export const BRAND_NO_IMAGE_AVAILABLE		=	"no-image.jpg";
export const USER_NO_IMAGE_AVAILABLE		=	"no-image.png";

/* Languages configurations */
export const ENGLISH_LANGUAGE_MONGO_ID	= "5a3a13238481824b077b23ca";
export const ENGLISH_LANGUAGE_CODE		= "en";

/* Default language configurations */
export const DEFAULT_LANGUAGE_CODE		= ENGLISH_LANGUAGE_CODE;
export const DEFAULT_LANGUAGE_ID		= ENGLISH_LANGUAGE_MONGO_ID;

/* Default country code */
export const DEFAULT_COUNTRY_CODE		= "+44";

export const LANGUAGES = [
	{"id" : ENGLISH_LANGUAGE_MONGO_ID, "name" : "English"}
];

/* Supported languages list */
export const LANGUAGE_CODES = {};
LANGUAGE_CODES[ENGLISH_LANGUAGE_MONGO_ID]	=	ENGLISH_LANGUAGE_CODE;

/** Mongodb Sorting types */
export const SORT_ASC = 1;
export const SORT_DESC = -1;

/** User Types */
export const USER 	= 'user';
export const VENDOR 	= 'vendor';

/** For currency symbol */
export const CURRENCY_SYMBOL = '£';

/* Supported languages list at product add and edit section */
export const LANGUAGE_SHORT_CODES_WITH_TITLE = [
	{"id" : ENGLISH_LANGUAGE_MONGO_ID, "name" : "English", "short_code"	: ENGLISH_LANGUAGE_CODE},
];

/** Google api  key */
// export const GOOGLE_API_KEY	=	"AIzaSyAWRLyLBht0KOjpCEExUvn33PjA4iESzWw";
export const GOOGLE_API_KEY	=	"AIzaSyCkNE5W2YyELcsDsX8bLo2rSD0lRZJsLJg";

/** Date format **/
export const AM_PM_FORMAT_WITH_DATE			=	"YYYY/MM/DD hh:mm A";
export const RATING_DATE_TIME_FORMAT		=	"DD/MM/YY";
export const DATERANGE_DATE_TIME_FORMAT		=	"YYYY-MM-DD";
export const FIND_VENDOR_DATE_TIME_FORMAT	=	"YYYY-MM-DD";
export const JOB_LISTING_DATE_TIME_FORMAT	=	"DD MMM YYYY";
export const JOB_DATE_TIME_FORMAT			=	"DD MMM YYYY";
export const REQUIREMENT_DATE_TIME_FORMAT	=	"DD MMM YYYY";
export const DASHBOARD_DATE_TIME_FORMAT		=	"DD-MM-YYYY";
export const DATE_FORMAT		=	"DD-MM-YYYY";
export const PLAN_FORMAT		=	"DD MMM YYYY";
export const BIDS_DATE_FORMAT	=	"DD MMMM";
export const MESSAGE_SEND_TIME	=	"hh:mm A";
export const REVIEW_DATE_FORMAT	=	"MMM YYYY";
export const DOB_DATE_FORMAT	=	"DD/MM/yyyy";
export const DOB_DATE_PICKER_FORMAT	=	"DD/MM/YYYY";
export const TESTIMONIAL_DATE_FORMAT=	"MMMM YYYY";

/** Upload image configurations*/
export const ALLOWED_IMAGE_EXTENSIONS 	=	["jpg","jpeg","png"];

/** Commonly used status **/
export const ACTIVE 	=	1;
export const DEACTIVE	= 	0;
export const REJECTED	= 	2;

/* User Types */
export const USER_TYPE_CUSTOMER	= "customer";
export const USER_TYPE_VENDOR 	= "vendor";

/* Business Types */
export const BUSINESS_TYPE_INDIVIDUAL 	= "individual";

/* Vendor profile steps */
export const VENDOR_CREATE_PROFILE_FIRST_STEP	= "first";
export const VENDOR_CREATE_PROFILE_SECOND_STEP 	= "second";

/** API status */
export const STATUS_SUCCESS = "success";
export const STATUS_ERROR 	= "error";
export const COUNTRY_SHORT_CODE = "uk";

/* Subscription Plans */
export const SUBSCRIPTION_FREE_TRIAL = "free_trial";
export const SUBSCRIPTION_STANDARD 	 = "standard";
export const SUBSCRIPTION_PREMIUM 	 = "premium";

export const DISCOUNT_TYPE_FIXED 		= "fixed";
export const DISCOUNT_TYPE_PERCENTAGE	= "percentage";

export const ROUND_PRECISION = 2;

export const MIN_LIMIT_FOR_BUSINESS_IMAGES = 3;
export const MAX_LIMIT_FOR_BUSINESS_IMAGES = 5;
export const INTERESTED_VENDOR_LIMIT = 15;
export const LIMIT = 12;

export const BID_NEW 					=	"new";
export const BID_CONFIRMED 				=	"confirmed";
export const BID_REJECTED 				=	"rejected";
export const BID_NEGOTIATING			=	"negotiating";
export const BID_AWAITING_CONFIRMATION	=	"awaiting_confirmation";

export const BID_STATUS = {};
BID_STATUS[BID_NEW] 					=	{label: "New", level : 0 };
BID_STATUS[BID_AWAITING_CONFIRMATION] 	=	{label: "Awaiting Confirmation", level : 1 };
BID_STATUS[BID_NEGOTIATING] 			=	{label: "Negotiating",level : 2 };
BID_STATUS[BID_CONFIRMED] 				=	{label: "Confirmed",  level : 3 };
BID_STATUS[BID_REJECTED] 				=	{label: "Rejected", level : 4 };

export const ENQUIRY_NEW				= 	"new";
// export const ENQUIRY_IN_CONVERSATION 	= 	"in_conversation";
export const ENQUIRY_IN_PROCESS		 	= 	"in_process";
export const ENQUIRY_COMPLETED 			= 	"completed";

export const ENQUIRY_STATUS = {};
ENQUIRY_STATUS[ENQUIRY_NEW]  			=	{label: "New", level: 0};
//ENQUIRY_STATUS[ENQUIRY_IN_CONVERSATION] =	{label: "In Conversation" };
ENQUIRY_STATUS[ENQUIRY_IN_PROCESS]  	=	{label: "In Process", level: 2 };
ENQUIRY_STATUS[ENQUIRY_COMPLETED]  		=	{label: "Completed", level: 3 };

export const FRONT_BACK_RESOLUTION = "162*183";
export const BUSINESS_IMAGE_RESOLUTION = "564*564";

/* Guest Limits */
export const GUEST_LIMIT_NON_APPLICABLE = "non_applicable";

/* vendor location Limit */
export const VENDOR_LOCATION_MAX_LIMIT = 4;

/** Notification types */
export const NOTIFICATION_TO_ADMIN_AND_VENDOR_FOR_JOB_STATUS_UPDATE = 4;
export const NOTIFICATION_TO_USER_FOR_SUBSCRIPTION_PLAN_EXPIRED = 3;
export const NOTIFICATION_TO_CUSTOMER_FOR_JOB_BID = 5;
export const NOTIFICATION_TO_VENDOR_FOR_OFFER_ACCEPTED = 6;
export const NOTIFICATION_TO_USER_ON_NEW_MESSAGE = 9;