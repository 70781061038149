import './../assets/css/header.css';
import 'bootstrap/js/src/dropdown.js';

import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from "react-query";
import { logout, callBackendAPI } from './../helpers/common';
import {  USER_TYPE_VENDOR, USER_TYPE_CUSTOMER, ACTIVE, ENQUIRY_NEW, STATUS_SUCCESS  } from '../config/global_constants';
import { Context }  from './../Auth.Context';
import { translate } from './../helpers/intl_helpers';
import $ from 'jquery';
import Logo from './../assets/images/logo.svg';

export default function Header() {
	const [authDetails, setAuthDetails] 		=	useContext(Context);
	const [mainCategories, setMainCategories] 	=	useState([]);
	function Logout() {
		setAuthDetails({});
		logout();
	}// end Logout()

	useEffect(() => {
		$("body").click(function() {
			if(!$(".mobile-categories.collapse").hasClass("show")){
				$("#collapseExample, #collapseExample2, .category-overlap").removeClass("show");
				$("body").removeClass("stop-scroll");
			}
		});

		$(document).on("click","#collapse-cat-header",function(event) {
			event.stopPropagation();
			$("#collapseExample, .category-overlap").toggleClass("show");
			$(".dropdown-menu-end").removeClass("show");
		});

		$(document).on("click","#collapse-cat-header2",function(event) {
			event.stopPropagation();
			$("#collapseExample2, .category-overlap").toggleClass("show");
			$("body").toggleClass("stop-scroll");
			$(".mobile-cat").removeClass("show").attr("aria-expanded",false);
			$(".dropdown-menu-end").removeClass("show");
		});

		$(document).on("click",".categories-close",function() {
			$("#collapseExample, #collapseExample2, .category-overlap").removeClass("show");
			$("body").removeClass("stop-scroll");
		});
	}, []);

	const getHeaderPageData = () => {
		/** Get details */
		callBackendAPI([{model: 'home', method: 'getParentCategory' }]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				if(response.data[0].status === STATUS_SUCCESS && response.data[0].result) setMainCategories(response.data[0].result);
			}
		}).catch(err => console.log(err));
	}

	useQuery("getHeaderPageData", getHeaderPageData);

  	return <>
		<header className="header">
			<div className="container">
				<div className="header-inner d-flex align-items-center justify-content-between flex-wrap">
					<Link to={authDetails && authDetails.user_type === USER_TYPE_VENDOR ? "/vendor" :"/"} className="logo">
						<img src={Logo} alt="logo" width={126} height={86} />
					</Link>
					{authDetails && Object.keys(authDetails).length !== 0 ?
						<div className="d-flex align-items-center">
							<nav className="navigation">
								<ul className="d-flex align-items-center flex-wrap">
									{authDetails.user_type !== USER_TYPE_VENDOR && Object.keys(mainCategories).length > 0 ?
										<li className="after-login-user d-none d-lg-block category-dropdown">
											<button className="btn btn-outline" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" id="collapse-cat-header">
												{translate("header.categories")}
											</button>
											<div className="collapse " id="collapseExample">
												<div className="card card-body">
													<ul className='category-listing'>
														{mainCategories.map((mainCategory, mainCategoryIndex) =>
															<li key={"header-cat"+mainCategoryIndex}>
																<Link to={"/category/"+mainCategory["slug"]}>{mainCategory.name["en"]}</Link>
															</li>
														)}
													</ul>
												</div>
											</div>
										</li>
									:null}
									{(authDetails.user_type !== USER_TYPE_VENDOR || (authDetails.user_type === USER_TYPE_VENDOR && authDetails.is_approved === ACTIVE && authDetails.standard_expiry_date)) &&
										<>
											<li className="after-login-user d-none d-md-block">
												<Link to={authDetails.user_type === USER_TYPE_VENDOR ? "/vendor/notifications" : "/customer/notifications"}>
													<i className="fa-solid fa-bell" />
												</Link>
											</li>
											<li className="after-login-user ">
												<Link to={authDetails.user_type === USER_TYPE_VENDOR ? "/vendor/messages" : "/customer/messages"}> <span><i className="fa-solid fa-message"></i> </span> </Link>
											</li>
										</>
									}
									{authDetails.user_type !== USER_TYPE_VENDOR ?
										<li className="find-vendo ">
											<Link to="/find-vendor" className="btn btn-small btn-outline">{translate('header.find_vendor')}
												<i className="fa-solid fa-angle-right"></i>
											</Link>
										</li>
									:null}
								</ul>
							</nav>
							{(authDetails.user_type !== USER_TYPE_VENDOR || (authDetails.user_type === USER_TYPE_VENDOR && authDetails.is_approved === ACTIVE && authDetails.standard_expiry_date)) &&
								<>
									<div className="after-login-user d-block d-md-none me-3">
										<Link to={authDetails.user_type === USER_TYPE_VENDOR ? "/vendor/notifications" : "/customer/notifications"}> <i className="fa-solid fa-bell" /> </Link>
									</div>
									<div className="after-login-user d-block d-md-none me-3">
										<Link to={authDetails.user_type === USER_TYPE_VENDOR ? "/vendor/messages" : "/customer/messages"}> <span><i className="fa-solid fa-message"></i> </span> </Link>
									</div>
								</>
							}
							<div className="user-profile dropdown">
								<Link to="#" className="btn btn-small btn-outline dropdown-toggle  me-0 mobile-cat " role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-label="icon">
									<span><i className="fa-solid fa-bars"></i><i className="fa-solid fa-circle-user ms-3"></i></span>
								</Link>
								<ul className="dropdown-menu dropdown-menu-end">
									{/* <li className="after-login-user d-block d-md-none ">
										<Link to="#"> <span className="m-0">Welcome {authDetails && authDetails.full_name ? authDetails.full_name :""}!</span></Link>
									</li> */}
									{
										authDetails && authDetails.user_type === USER_TYPE_VENDOR &&
											<>
												{authDetails.is_approved === ACTIVE && authDetails.standard_expiry_date &&
													<li>
														<Link className="dropdown-item" to="/vendor/myinfo" >
															{translate('header.vendor_home')}
														</Link>
													</li>
												}
												{(authDetails.is_approved !== ACTIVE) || (authDetails.is_approved === ACTIVE && authDetails.standard_expiry_date) &&
												<li>
													<Link className="dropdown-item" to="/vendor">
														{translate('header.dashboard')}
													</Link>
												</li>
												}
												{authDetails.is_approved === ACTIVE &&
													<>
													{authDetails.standard_expiry_date &&
														<>
														<li>
															<Link className="dropdown-item" to={"/vendor/job-list/"+ENQUIRY_NEW}>
																{translate('header.new_jobs')}
															</Link>
														</li>
														<li>
															<Link className="dropdown-item" to="/vendor/mybids">
																{translate('header.my_submitted_bids')}
															</Link>
														</li>
														<li>
															<Link className="dropdown-item" to="/vendor/reviews">
																{translate('header.my_reviews')}
															</Link>
														</li>
														</>
														}
														<li>
															<Link className="dropdown-item" to="/vendor/subscription">
																{translate('header.subscription_plans')}
															</Link>
														</li>
													</>
												}
												<li>
													<Link className="dropdown-item" to="vendor/change-password">
														{translate('header.change_password')}
													</Link>
												</li>
											</>
									}
									{
										authDetails && authDetails.user_type === USER_TYPE_CUSTOMER &&
											<>
												<li>
													<Link className="dropdown-item" to="/customer">
														{translate('header.dashboard')}
													</Link>
												</li>
												{Object.keys(mainCategories).length > 0 ?
													<li className="after-login-user d-block d-lg-none category-dropdown">
														<button className="btn btn-outline mobile-cate" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2" id="collapse-cat-header2">
															{translate("header.categories")}
														</button>
													</li>
												:null}
												<li>
													<Link className="dropdown-item d-block d-md-none " to="/find-vendor">
														{translate('header.find_vendor')}
													</Link>
												</li>
												<li>
													<Link className="dropdown-item" to="customer/profile">
														{translate('header.edit_profile')}
													</Link>
												</li>
												<li>
													<Link className="dropdown-item" to="customer/change-password">
														{translate('header.change_password')}
													</Link>
												</li>
												<li>
													<Link className="dropdown-item" to="/customer/reviews">
														{translate('header.reviews')}
													</Link>
												</li>
											</>
									}
									<li>
										<Link className="dropdown-item" to="#" onClick={() => Logout()}>
											{translate('header.logout')}
										</Link>
									</li>
								</ul>
								{Object.keys(mainCategories).length > 0 ?
									<div className="mobile-categories collapse " id="collapseExample2">
										<div className="card card-body">
											<ul className='category-listing'>
												{mainCategories.map((mainCategory, mainCategoryIndex) =>
													<li key={"header-mob-cat"+mainCategoryIndex}>
														<Link to={"/category/"+mainCategory["slug"]}>{mainCategory.name["en"]}</Link>
													</li>
												)}
											</ul>
											<button className='categories-close'>
												<i className="fa-solid fa-xmark"/>
											</button>
										</div>
									</div>
								:null}
							</div>
						</div>
					:
						<div className="d-flex align-items-center">
							<nav className="navigation">
								<ul className="d-flex align-items-center flex-wrap">
									{Object.keys(mainCategories).length > 0 ?
										<li className="after-login-user d-none d-lg-block category-dropdown">
											<button className="btn btn-outline" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" id="collapse-cat-header">
												{translate("header.categories")}
											</button>
											<div className="collapse " id="collapseExample">
												<div className="card card-body">
													<ul className='category-listing'>
														{mainCategories.map((mainCategory, mainCategoryIndex) =>
															<li key={"header-cat"+mainCategoryIndex}>
																<Link to={"/category/"+mainCategory["slug"]}>{mainCategory.name["en"]}</Link>
															</li>
														)}
													</ul>
												</div>
											</div>
										</li>
									:null}
									<li className="">
										<Link to="/become-vendor">{translate('header.become_vendor')}</Link>
									</li>
									<li className="find-vendo">
										<Link to="/find-vendor" className="btn btn-small btn-outline">
											{translate('header.find_vendor')} <i className="fa-solid fa-angle-right" />
										</Link>
									</li>
								</ul>
							</nav>
							<div className="user-profile dropdown">
								<Link to="/#" className="btn btn-small btn-outline dropdown-toggle me-0" role="button" data-bs-toggle="dropdown" aria-expanded="false"  aria-label="icon">
								<span><i className="fa-solid fa-bars"></i><i className="fa-solid fa-circle-user ms-3"></i></span>
								</Link>
								<ul className="dropdown-menu dropdown-menu-end">
									<li><Link className="dropdown-item" to="/customer-login">{translate('header.login')}</Link></li>
									<li><Link className="dropdown-item" to="/customer-signup">{translate('header.sign_up')}</Link></li>
									<li><Link className="dropdown-item" to="/login">{translate('header.business_login')}</Link></li>
									{Object.keys(mainCategories).length > 0 ?
										<li className="d-block d-md-none category-dropdown">
											<button className="btn btn-outline mobile-cate" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2" id="collapse-cat-header2">
												{translate("header.categories")}
											</button>
										</li>
									:null}
									<li className="d-block d-md-none">
										<Link to="/become-vendor" className='dropdown-item'>{translate('header.become_vendor')}</Link>
									</li>
									<li className="find-vendo d-block d-md-none">
										<Link to="/find-vendor" className="dropdown-item">
											{translate('header.find_vendor')}
										</Link>
									</li>
								</ul>
								{Object.keys(mainCategories).length > 0 ?
									<div className="mobile-categories collapse " id="collapseExample2">
										<div className="card card-body">
											<ul className='category-listing'>
												{mainCategories.map((mainCategory, mainCategoryIndex) =>
													<li key={"header-mob-cat"+mainCategoryIndex}>
														<Link to={"/category/"+mainCategory["slug"]}>{mainCategory.name["en"]}</Link>
													</li>
												)}
											</ul>
											<button className='categories-close'>
												<i className="fa-solid fa-xmark"/>
											</button>
										</div>
									</div>
								:null}
							</div>
						</div>
					}
				</div>
			</div>
			<div className="category-overlap "></div>
		</header>
	</>
};