import React, {lazy, Suspense} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import VendorProtectedRoute from './VendorProtectedRoute';
import CustomerProtectedRoute from './CustomerProtectedRoute';
import PublicOnlyRoute from './PublicOnlyRoute';
import PublicOrCustomerProtectedRoute from './PublicOrCustomerProtectedRoute';

import Layout from "./../layout/Layout";
const Home = lazy(()=> import("./../components/Home"));
const ContatctUs = lazy(()=> import("./../components/ContatctUs"));
const Cms = lazy(()=> import("./../components/Cms"));
const FindVendor = lazy(()=> import("./../components/FindVendor"));
const Page404 = lazy(()=> import("./../components/Page404"));
const ScrollToTop = lazy(()=> import("./../ScrollToTop"));

/** Common before login  */
const ForgotPassword = lazy(()=> import("./../components/ForgotPassword"));
const ForgotPasswordVerify = lazy(()=> import("./../components/ForgotPasswordVerify"));
const ResetPassword = lazy(()=> import("./../components/ResetPassword"));

/** Common after login  */
const ChangePassword = lazy(()=> import("./../components/common/ChangePassword"));
const Notification = lazy(()=> import("./../components/common/Notification"));
const Messages = lazy(()=> import("./../components/common/Messages"));
const VerifyMobile = lazy(()=> import("./../components/common/VerifyMobile"));

/** Vendor before login */
const Login = lazy(()=> import("./../components/Login"));
const BecomeVendor = lazy(()=> import("./../components/BecomeVendor"));
const BecomeVendorStepTwo = lazy(()=> import("./../components/BecomeVendorStepTwo"));
const BecomeVendorStepThree = lazy(()=> import("./../components/BecomeVendorStepThree"));
const VendorVerify = lazy(()=> import("./../components/VendorVerify"));

/** Vendor after login */
const VendorDashboard = lazy(()=> import("./../components/vendor/Dashboard"));
const VendorProfile = lazy(()=> import("./../components/vendor/Profile"));
const VendorMyInfo = lazy(()=> import("./../components/vendor/MyInfo"));
const VendorServices = lazy(()=> import("./../components/vendor/Service"));
const VendorMyBids = lazy(()=> import("./../components/vendor/MyBids"));
const Reviews = lazy(()=> import("./../components/vendor/Reviews"));
const JobDetail = lazy(()=> import("./../components/vendor/JobDetail"));
const JobListing = lazy(()=> import("./../components/vendor/JobListing"));
const Subscription = lazy(()=> import("./../components/vendor/Subscription"));
const VendorsProfileDetails = lazy(()=> import("./../components/vendor/VendorDetails"));

/** Customer before login */
const CustomerLogin = lazy(()=> import("./../components/CustomerLogin"));
const CustomerSignUp = lazy(()=> import("./../components/CustomerSignUp"));
const CustomerVerify = lazy(()=> import("./../components/CustomerVerify"));

/** Customer after login */
const CustomerDashboard = lazy(()=> import("./../components/customer/Dashboard"));
const CustomerProfile = lazy(()=> import("./../components/customer/EditProfile"));
const InterestedVendors = lazy(()=> import("./../components/customer/InterestedVendors"));
const VendorsDetails = lazy(()=> import("./../components/customer/InterestedVendorsDetail"));
const CustomerJobDetail = lazy(()=> import("./../components/customer/CustomerJobDetail"));
const CustomerReviews = lazy(()=> import("./../components/customer/Reviews"));

/** Payment routes */
const PaymentSuccess= lazy(()=> import("./../components/PaymentSuccess"));
const Category 		= lazy(()=> import("./../components/Category"));
const VendorDetails = lazy(()=> import("./../components/VendorDetails"));

export default function AppRoutes() {
	return (
		<BrowserRouter>
			<Suspense fallback={<div></div>}>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					<Route path="contact-us" element={<ContatctUs />} />
					<Route path="cms/:slug" element={<Cms />} />
					<Route path="payment/success" element={<PaymentSuccess />} />

					<Route element={ <PublicOrCustomerProtectedRoute/> }>
						<Route path="find-vendor" element={<FindVendor />} />
						<Route path="find-vendor/:category_slug" element={<FindVendor />} />
						<Route path="category/:category_slug" element={<Category />} />
						<Route path="vendor-details/:category_slug/:vendor_slug/:service_id" element={<VendorDetails />} />
					</Route>

					<Route element={ <PublicOnlyRoute/> }>
						<Route path="login" element={<Login />} />
						<Route path="forgot-password/:user_type" element={<ForgotPassword />} />
						<Route path="forgot-password-verify/:slug/:validate_string" element={<ForgotPasswordVerify />} />
						<Route path="reset-password/:validate_string" element={<ResetPassword /> }/>
						<Route path="customer-login" element={  <CustomerLogin /> }/>
						<Route path="customer-signup" element={<CustomerSignUp />} />
						<Route path="verify-customer/:slug" element={<CustomerVerify />} />

						<Route path="become-vendor" element={<BecomeVendor />} />
						<Route path="become-vendor-step-two/:slug" element={<BecomeVendorStepTwo />} />
						<Route path="become-vendor-step-three/:slug" element={<BecomeVendorStepThree />} />
						<Route path="verify-vendor/:slug" element={<VendorVerify />} />
					</Route>

					<Route path="customer" element={ <CustomerProtectedRoute/> }>
						<Route index={true} element={  <CustomerDashboard /> }/>
						<Route path="change-password" element={  <ChangePassword /> }/>
						<Route path="notifications" element={  <Notification /> }/>
						<Route path="messages" element={  <Messages /> }/>
						<Route path="profile" element={  <CustomerProfile /> }/>
						<Route path="interested-vendors/:job_id" element={  <InterestedVendors /> }/>
						<Route path="vendor-details/:job_id/:vendor_id" element={  <VendorsDetails /> }/>
						<Route path="verify-mobile/:user_type/:slug" element={<VerifyMobile />} />
						<Route path="job-details/:job_id" element={  <CustomerJobDetail /> }/>
						<Route path="reviews" element={  <CustomerReviews /> }/>
					</Route>

					<Route path="vendor" element={ <VendorProtectedRoute/> }>
						<Route index={true} element={ <VendorDashboard /> }/>
						<Route path="profile" element={ <VendorProfile /> }/>
						<Route path="verify-mobile/:user_type/:slug" element={<VerifyMobile />} />
						<Route path="myinfo" element={ <VendorMyInfo /> }/>
						<Route path="change-password" element={ <ChangePassword /> }/>
						<Route path="service/:category_slug" element={  <VendorServices /> }/>
						<Route path="service/:category_slug/:service_id" element={  <VendorServices /> }/>
						<Route path="notifications" element={  <Notification /> }/>
						<Route path="messages" element={  <Messages /> }/>
						<Route path="mybids" element={  <VendorMyBids /> }/>
						<Route path="mybids/:status" element={  <VendorMyBids /> }/>
						<Route path="reviews" element={  <Reviews /> }/>
						<Route path="job-details/:job_id" element={  <JobDetail /> }/>
						<Route path="job-list" element={  <JobListing /> }/>
						<Route path="job-list/:job_status" element={  <JobListing /> }/>
						<Route path="subscription" element={<Subscription />} />
						<Route path="profile-view" element={<VendorsProfileDetails />} />
					</Route>

					<Route path="*" element={<Page404 />} />
				</Route>
		  	</Routes>
			</Suspense>
		</BrowserRouter>
	);
}