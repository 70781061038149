import React, { useContext } from 'react';
import {Navigate, Outlet } from 'react-router-dom';
import { Context }  from './../Auth.Context';
import { USER_TYPE_CUSTOMER, USER_TYPE_VENDOR } from '../config/global_constants';

const PublicOnlyRoute = () => {
	const [userDetails,] = 	useContext(Context);

	if(!userDetails || !userDetails._id){
		return <Outlet />
	}
	else if (userDetails && userDetails.user_type === USER_TYPE_CUSTOMER ) return <Navigate to="/customer" />;
	else if (userDetails && userDetails.user_type === USER_TYPE_VENDOR  ) return <Navigate to="/vendor" />;
	else return null;
};
export default PublicOnlyRoute;
