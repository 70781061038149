import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import axios from "axios";
import { toast } from 'react-toastify';
import base64 from 'base-64';
import Cookies from 'universal-cookie';
import { confirmAlert } from 'react-confirm-alert';
import jwtDecode from "jwt-decode";

import axiosInstance from '../config/axios_interceptor.js'
import { API_URL, ROUND_PRECISION, STATUS_SUCCESS, STATUS_ERROR }  from '../config/global_constants';
import { translate }  from './intl_helpers';

export async function callBackendAPI(request, source = {}, showError = true){
    let sourceToken =   (source.token) ? { cancelToken: source.token } : {};
	let response 	=   await axios.all(request.map(params => axiosInstance.post( API_URL+'api/'+ params.model + '/' + params.method , params, sourceToken)));
    let errorFlag 	=   false;
    let isLogout 	=   false;
    let errorRecord =   [];
    let apiResponse =   response.map( el => {
        let responseData = el.data;
        if(responseData.status === STATUS_ERROR && !errorFlag && !responseData.is_logout){
            errorFlag	= true;
            errorRecord = (responseData.message) ? responseData.message : [];
        }

        if(responseData.is_logout) isLogout = true;
        return responseData;
    });

    if(showError && errorFlag && errorRecord.length>0) reFormatErrors(errorRecord);

    /** Logout user */
    if(isLogout) return logout(isLogout);

    return {success: !errorFlag, data: apiResponse};
};

/* Call backend APIs by axios */
export async function callBackendAPIAndImageUpload(requestData){
    const cookies 	=	new Cookies();
	let userDetails =	cookies.get('user_details');

    let tmpHeaders = {"authkey": process.env.REACT_APP_API_HEADER_AUTH_KEY,'Content-Type': 'multipart/form-data'};
	if(userDetails && userDetails.token) tmpHeaders.authorization = userDetails.token;

    let response = await axios.post( API_URL+'api/'+requestData.model+"/"+requestData.method,requestData.formData,{headers: tmpHeaders });

    let responseData = {};
    if(response.data) responseData = JSON.parse(base64.decode(response.data));

    if(responseData.status !== STATUS_SUCCESS) scrollToErrorDiv();

    return {
        success : true,
        data 	: responseData
    };


    // req.headers.authorization
};// end callBackendAPI()

//handle Inputs
export async function handleFormInputs(e, field, state) {
    let fields 				=	state;
    let oldValues           =   (fields[field] && fields[field].value) ? fields[field].value :"";
	fields[field] 			= 	{};
    fields[field]["type"] 	= 	e.target.type;
    let value 				=	e.target.value;

    if(e.target.type === "select-multiple"){
        value	=	[];
        let options = e.target.options;
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected && options[i].value) {
                value.push(options[i].value);
            }
        }
    }else if(e.target.type === "file"){
        value = (e.target.files.length === 1) ? e.target.files[0] : e.target.files
        fields[field]["file_count"] = e.target.files.length;
    }

    if(oldValues && e.target.type === "checkbox"){
        let coreVal     =   e.target.value;
        let currentVal  =   (e.target.checked) ? value :"";

        if(oldValues){
            if(oldValues.constructor === Array && oldValues.length >0){
                if(!currentVal && oldValues.indexOf(coreVal) !== -1) {
                    oldValues.splice(oldValues.indexOf(coreVal),1)
                    currentVal = (oldValues.length ===1) ? oldValues[0] :oldValues;
                }else if(currentVal){
                    oldValues.push(currentVal);
                    currentVal = oldValues;
                }
            }else if(oldValues.constructor === String){
                if(currentVal){
                    let tmpArray = [oldValues,currentVal];
                    currentVal = tmpArray;
                }
            }
        }
        fields[field]["value"] = currentVal;
    }else{
        fields[field]["value"] = (e.target.type === "checkbox") ? ((e.target.checked) ? value :"") : value;
    }
    return fields;
}

export async function handleFormValidation(validations,state, params, sendToAPI = true, showSuccessFlash = true){
    let fields 		=	state;
    let errors 		= 	[];
    let formIsValid = 	true;
    /** Validate form data */
    if(validations.constructor === Object && Object.keys(validations).length >0){
        Object.keys(validations).forEach(field =>{
            var value 			=	(fields[field] && typeof fields[field].value !== typeof undefined) ? fields[field].value :"";
            var validatorRules	=	validations[field].rules;
            var fieldLabel		=	validations[field].label.toLowerCase();
            var targetFieldValue=	(field === "confirm_password" && fields.password && fields.password.value) ?  fields.password.value :"";
            var msg				= 	validator(fieldLabel,validatorRules,value,targetFieldValue);

            if(msg){
                formIsValid  = false;
                errors.push({param :field , msg : msg});
            }
        });
    }

    /** Send error response **/
    if(!formIsValid){
        scrollToErrorDiv();

        return{"success": false, "errors": reFormatErrors(errors)};
    }

    /** Send succcess response **/
    if(!sendToAPI) return {"success": true};

	//put formIsValid in param of if for running below code
    if(formIsValid){
        let result 	= 	await callBackendAPI(params,false, false);
        if(result.data[0].status === STATUS_SUCCESS){
            /** Send success response **/
            return{"success": true, "msg": (showSuccessFlash) ? reFormatSuccess(result.data[0].message) :"", "data":result.data[0]};
        }else{
            scrollToErrorDiv();

            /** Send error response **/
            return{"success": false, "errors": reFormatErrors(result.data[0].message)};

        }
    }
}

function validator(field, rules, value,targetFieldValue){
    /** Basic validation message **/
    var messages = {
        require		:	translate("basic_validation.please_enter_field"),
        select		:	translate("basic_validation.please_select_field"),
        terms		:	translate("basic_validation.please_accept_field"),
        valid_location:	translate("basic_validation.please_select_valid_field"),
        integer	 	:	translate("basic_validation.field_must_be_number"),
        year		:	translate("basic_validation.field_must_be_formatted_as_a_year"),
        decimal		:	translate("basic_validation.field_must_be_numeric_or_more_than_one"),
        date		:	translate("basic_validation.field_must_be_formatted_as_a_date"),
        email		:	translate("basic_validation.please_enter_valid_email"),
        url			:	translate("basic_validation.field_must_be_formatted_as_url"),
        number		:	translate("basic_validation.field_should_be_numeric"),
        tooLong		:	translate("basic_validation.field_cannot_be_longer_than_max_characters"),
        tooShort	:	translate("basic_validation.field_length_should_be_digits_long"),
        equal		:	translate("basic_validation.field_should_be_same_as_target_field"),
        email_mobile:	translate("basic_validation.please_enter_valid_email_mobile_numnber"),
        mobile_number:	translate("basic_validation.please_enter_valid_mobile_number"),
        otp         :	translate("basic_validation.please_enter_valid_otp"),
        iframe      :	translate("basic_validation.please_enter_valid_iframe"),
    };

    /** Basic validation regex **/
    var patterns ={
        year		:	/^\d{4}$/,
        integer		:	/^[0-9]+$/,
        decimal		:	/^[1-9]\d*(\.\d+)?$/,
        email		:	/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,12})+$/,
        url			:	/\^\(\(\[\^<>\(\)\\\[\\\]\\\.,;:\\s@\\"\]\+\(\\\.\[\^<>\(\)\\\[\\\]\\\.,;:\\s@\\"\]\+\)\*\)\|\(\\"\.\+\\"\)\)@\(\(\[\^<>\(\)\[\\\]\\\.,;:\\s@\\"\]\+\\\.\)\+\[\^<>\(\)\[\\\]\\\.,;:\\s@\\"\]\{2,\}\)\$/i,
        mobile_number	:	/^([0-9]{10})+$/,
        iframe	    :	/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g,
    };

    var msg = "";
    if(!value || value.constructor === String){
        value	= (value) ? value.trim() :"";
        for(var i = 0; i < rules.length; i++){
            var validtionMethod = rules[i];
            switch(validtionMethod){
                case "required":
                    if(!value) msg = messages.require;
                    break;
                case "select":
                    if(!value) msg = messages.select;
                    break;
                case "valid_location":
                    if(!value) msg = messages.valid_location;
                    break;
                case "email":
                    if(!patterns.email.test(value)) msg = messages.email;
                    break;
                case "email_mobile":
                    if(!patterns.email_mobile.test(value)) msg = messages.email_mobile;
                    break;
                case "mobile_number":
                    if(!patterns.mobile_number.test(value)) msg = messages.mobile_number;
                    break;
                case "minLength":
                    if(value.length < 8 || value.length > 16) msg = messages.tooShort;
                    break;
                case "equal":
                    field = field.charAt(0).toUpperCase() + field.slice(1);
                    if(value !== targetFieldValue)  msg = messages.equal.replace(/{target_field}/,"password");
                    break;
                case "integer":
                    if(!patterns.integer.test(value)) msg = messages.integer;
                    break;
                case "decimal":
                    if(!patterns.decimal.test(value)) msg = messages.decimal;
                    break;
                case "year":
                    if(!patterns.year.test(value)) msg = messages.year;
                    break;
                case "otp":
                    if(value.length < 6) msg = messages.otp;
                    break;
                case "terms":
                    if(!value) msg = messages.terms;
                    break;
                case "url":
                    if(!patterns.url.test(value)) msg = messages.url;
                break;
                case "iframe":
                    if(!patterns.iframe.test(value)) msg = messages.iframe;
                break;
                default:
                    break;
            }
            if(msg) break;
        };
    };
    field = (field.replace(/_/g," "));
    return ucfirst(msg.replace(/{field}/,field));
}// end validator();

export function reFormatErrors(errorArray){
    var errorObj = {};
    if(errorArray && errorArray.constructor === Array && errorArray.length >0){
        errorArray.forEach(records=>{
            if(records.param && records.msg){
                if(records.param === "invalid-access"){
                    notice('error',records.msg);
                }else if(records.param === STATUS_ERROR){
                    notice('error',records.msg);
                }else{
                    errorObj[records.param]	 = records.msg;
                }
            }
        });
    }else if(errorArray && errorArray.constructor === String){
        notice('error',errorArray);
    }
    return errorObj;
}// end reFormatErrors();

export function reFormatSuccess(successArray){
    var successObj = {};
    if(successArray && successArray.constructor === Array && successArray.length >0){
        successArray.forEach(records=>{
            if(records.param && records.msg){
                if(records.param === STATUS_SUCCESS && records.msg !== STATUS_SUCCESS){
                    notice('success',records.msg);
                }else{
                    successObj[records.param]	 = records.msg;
                }
            }
        });
    }else{
        notice('success',successArray);
    }
    return successObj;
}// end reFormatSuccess();

export async function notice(type,message,timeout,displayPosition,showHideTransition){
    displayPosition 	= (displayPosition === "undefined") 	? 	displayPosition 	: toast.POSITION.TOP_RIGHT;
    showHideTransition 	= (showHideTransition === "undefined")	?	showHideTransition	:"fade";
    timeout 			= (timeout === "undefined") 			?	timeout 			:10000;

    toast.dismiss();
    if (message !== "" && message !== undefined) {
        if(type === STATUS_SUCCESS) toast.success(message, { position: displayPosition });
        if(type === STATUS_ERROR) toast.error(message, { position: displayPosition });
    }
}// end notice()


function ucfirst(s){
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1);
}// end ucfirst()

/** Logout the user account **/
export async function logout(isShowMsg){
    const cookies 	=	new Cookies();
    let userDetails = 	cookies.get('user_details');
    let userId      =   (userDetails && userDetails._id) ? userDetails._id :"";

    /** Remove cookies  */
    cookies.remove("user_details",{ path: '/' });

    if(userId) await callBackendAPI([{model: 'registration', method:'logOut', user_id: userId }],{},false);

    setTimeout(()=>{
        window.location.href = '/';
    },500);
    return;
}// end logout()

export  function convertToProtectedEmail(emailStr){
    if(!emailStr) return emailStr;

    emailStr            =   String(emailStr);
    let firstSt         =   (emailStr.split("@")[0]) ? emailStr.split("@")[0] :"";
    let secondSt        =   (emailStr.split("@")[1]) ? emailStr.split("@")[1].split(".")[0] :"";
    let subStr          =   (emailStr.split("@")[1] && emailStr.split("@")[1].split(".")[1]) ? emailStr.split("@")[1].split(".")[1] :"";
    let finalStr        =   firstSt.substr(0,3)+firstSt.substr(3,firstSt.length).replaceAll(/./g, '*')+"@"+secondSt.substr(0,1)+secondSt.substr(1,secondSt.length).replaceAll(/./g, '*')+"."+subStr;
    return finalStr;
}// End convertToProtectedEmail()

export  function convertToProtectedMobileNumber(mobStr){
    if(!mobStr) return mobStr;

    mobStr          =   String(mobStr);
    let finalStr    =   mobStr.substr(0,mobStr.length-4).replaceAll(/./g, '*')+mobStr.substr(mobStr.length-4,mobStr.length);
    return finalStr;
}// End convertToProtectedMobileNumber()

export async function loadSlider(){
	var list = document.getElementsByClassName('data-img-bg');
	for (var i = 0; i < list.length; i++) {
		var src = list[i].getAttribute('data-image-src');

		list[i].style.backgroundImage="url('" + src + "')";
	}
}

export async function showSelectedCategory(value, slug){
    if(document.querySelector('.form-box .dropdown .dropdown-toggle')) document.querySelector('.form-box .dropdown .dropdown-toggle').innerHTML = value;
    if(document.getElementById("renderToCategory")) document.getElementById("renderToCategory").setAttribute("data-href", "/find-vendor/" + slug);
}

/** For confirm box */
export function customConfirmBox(message,title){
    return new Promise((resolve) => {
        if(!title) title = translate("system.are_you_sure");
        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {
                    label: translate("system.yes"),
                    onClick: () => {resolve(true)}
                },
                {
                    label: translate("system.no"),
                    className: "btn-outline",
                    onClick: () => {resolve(false)}
                }
            ]
        });
    });
}// end customConfirmBox()

/**
 *  Function to Round the number
 *
 * @param value		As Number To be round
 * @param precision As Precision
 *
 * @return number
 */
export  function customRound(value, precision){
	try{
		if(!value || isNaN(value)){
			return value;
		}else{
			precision = (typeof precision != typeof undefined) ? precision :ROUND_PRECISION;
			var multiplier = Math.pow(10, precision || 0);
			return Math.round(value * multiplier) / multiplier;
		}
	}catch(e){
		return value;
	}
}// end round()

/**
 * For scroll to first error div
 *
 *@param null
*
* @return null
*/
export function scrollToErrorDiv(){
    setTimeout(function(){
        let errorDiv = document.getElementsByClassName("error");
        let isHave = false;
        for (var i = 0; i < errorDiv.length; i++) {
            if(!isHave && document.getElementsByClassName("error")[i].innerHTML){
                isHave = true;
                let hei = document.getElementsByClassName("error")[i].getBoundingClientRect().top + window.scrollY-80;
                window.scroll({top: hei,behavior: 'smooth'});
            }
        }
    },100)
    return;
}// end scrollToErrorDiv();

/**
 * For scroll to top
 *
 *@param null
*
* @return null
*/
export function scrollToTop(elementId){
    setTimeout(function(){
        if(elementId){
            if(document.getElementById(elementId)){
                let hei = document.getElementById(elementId).getBoundingClientRect().top + window.scrollY-80;
                window.scroll({top: hei,behavior: 'smooth'});
            }
        }else{
            window.scrollTo(-100, 0);
        }
    },10);
    return;
}// end scrollToErrorDiv();

/**
 * For downloading image
 *
 *@param null
*
* @return null
*/
export function downloadFile(path){
	if(!path) return path;

	let imageEncoded	=	base64.encode(path);
    return window.location.href = API_URL+'api/download_image/'+imageEncoded;
}// end downloadFile();

/**
 * For decode jwt
 *
 *@param decode apple token
*
* @return encode object
*/
export function decodeAppleToken(decodeToken){
	if(!decodeToken) return {};
    try {
		return  jwtDecode(decodeToken);
	} catch(err) {
		return {};
	}
}// end decodeAppleToken();