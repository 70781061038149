import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./../elements/Header";
import Footer from "./../elements/Footer";
import Cookies from 'universal-cookie';
import { Context }  from './../Auth.Context';

const Layout = () => {
	const cookies = new Cookies();
	const [userDetails, setUserDetails] = useState(cookies.get('user_details') || {});	 
	return (
		<>
			 <Context.Provider value={[userDetails, setUserDetails]}>
				<Header />
				<Outlet />
				<Footer />
			</Context.Provider>
		</>
	)
};
export default Layout;
