import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Context }  from './../Auth.Context';
import { USER_TYPE_VENDOR, ACTIVE } from '../config/global_constants';

export default function VendorProtectedRoute() {
	const [userDetails,] = 	useContext(Context);
	const { pathname } 	=	useLocation();

	if(userDetails && userDetails._id && userDetails.user_type === USER_TYPE_VENDOR){
		if(userDetails.is_approved === ACTIVE || pathname === "/vendor" || pathname === "/vendor/change-password"){
			if(!userDetails.standard_expiry_date && userDetails.is_approved === ACTIVE && pathname !== "/vendor/subscription" && pathname !== "/vendor/change-password"){
				return <Navigate to="/vendor/subscription" />;
			}
			return <Outlet />
		}else{
			return <Navigate to="/vendor" />;
		}

	}
	else if (!userDetails || !userDetails._id){
		return <Navigate to="/login" />;
	}
	else if (userDetails.user_type !== USER_TYPE_VENDOR){
		return <Navigate to="/customer" />;
	}
	else {
		return null;
	}
}// end VendorProtectedRoute()